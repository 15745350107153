import { graphql, PageProps } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout';
import Posts from '../components/posts';

type Props = {
  pageContext: {
    tag: string;
    totalCount: number;
  };
};

const TagPage: React.FC<PageProps<GatsbyTypes.TagIndexQuery> & Props> = ({ pageContext, data }) => {
  const posts = data.allMdx.nodes;
  return (
    <Layout pageTitle="Top" description="jiko21's techblog" url="">
      <h2>
        {pageContext.tag}のタグを含む記事 {pageContext.totalCount}件
      </h2>
      {posts.map((node) => (
        <Posts key={node.id} node={node} />
      ))}
    </Layout>
  );
};

export const query = graphql`
  fragment BlogTagDataInfo on Mdx {
    id
    slug
    frontmatter {
      title
      date(formatString: "YYYY/MM/DD")
      tags
    }
  }

  query TagIndex($tag: String) {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { tags: { in: [$tag] } } }) {
      totalCount
      nodes {
        ...BlogTagDataInfo
      }
    }
  }
`;

export default TagPage;
