import { Link } from 'gatsby';
import * as React from 'react';
import * as styles from './style.module.css';
import Info from '../info';

interface IProps {
  node: GatsbyTypes.BlogDataInfoFragment;
}

const Posts: React.VFC<IProps> = ({ node }) => {
  return (
    <article key={node.id}>
      <Link className={styles.titlelink} to={`/blog/${node.frontmatter?.date?.replace(/\//g, '-')}/${node.slug}`}>
        <h2>{node.frontmatter?.title}</h2>
      </Link>
      <Info tags={node.frontmatter?.tags} date={node.frontmatter?.date} />
    </article>
  );
};

export default Posts;
